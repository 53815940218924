#content {
    position: relative;
    z-index: 1;
    width: 100%;

    .img_item {
        width: 100%;
        height: 700px;
        object-fit: cover;
    }

    h5 {
        font-size: 28px;
    }
}

.img_item div {
    bottom: 20% !important;
}

.carousel {
    z-index: 1 !important;
}

.carousel-item {
    transition: transform .5s ease-in-out !important;
}

.carousel-control-prev {
    opacity: 0.8 !important;
}

.carousel-control-next {
    opacity: 0.8 !important;
}

.carousel-control-prev {
    height: auto;
}

.carousel-indicators {
    bottom: 18% !important;

    button {
        background-color: white !important;
    }

    .active {
        background-color: rgb(33, 81, 209) !important;
        opacity: 1 !important;
    }
}

.carousel-item {
    transition: transform .3s ease-in-out !important; /* 将时间修改为所需的持续时间 */
}

.new_area {
    font-family: TencentSansW7;
    font-size: 28px;
    font-weight: 900;
    color: white;
    margin-bottom: 10px;
}

.cards_area {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin-top: -120px;
    z-index: 4;

    @media (max-width: 770px) {
        padding: 15px;
    }
}

.cards_container {
    border-top: 1px solid white;
    max-width: 1200px;
    margin: auto;
    padding-top: 20px;
}

.cards {
    width: 100%;
    position: relative;

    .row {
        max-width: 100%;
        margin: auto;
        flex-wrap: wrap !important;
    }
}

.card_item {
    /*height: 500px;*/
    position: relative;
    text-decoration: none;
    /*border: none!important;*/
    overflow: hidden;
    box-sizing: border-box;

    img {
        width: 100%;
        object-fit: cover;
    }
}

.carousel-shadow {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.card-text {
    margin-top: 30px;
    font-size: 20px;
}

.card_item:hover {
    transform: translateY(-5px);
    transition: all 0.3s;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.1);

    img {
        transform: scale(1.05); /* 在悬停时放大图片 */
        transition: all 0.3s;
    }
}

.card_item {
    a {
        font-size: 24px;
        text-decoration: none;
        word-spacing: 3px;
    }

    a:hover {
        color: #0052D9;
    }

    box-sizing: border-box;
}


.date-container {
    margin-top: 20px;
    width: 150px;
    font-weight: 600;
    position: relative;
    height: 30px;
    line-height: 24px;
    overflow: hidden;
    border-top: 1px solid #0052D9;
    font-size: 14px;
    padding: 0 30px;
    color: #0052D9;
    display: inline-block;
}


.date-container:before {
    content: "";
    display: block;
    position: absolute;
    left: calc(100% - 12px);
    bottom: 0px;
    width: 100%;
    border-top: 1px solid #0052D9;
    transform: rotate(-69deg) translate(-1px, 0px);
    transform-origin: 0px 0px;
}


.date-container:after {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: calc(100% - 12px);
    border-top: 1px solid #0052D9;
}

.one-row {
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-bottom: 40px;

    a {
        flex-basis: 0;
    }

    .card_item {
        border: none;
        border-radius: 2px;
    }

    .card-img-top {
        border-radius: 0;
    }

    @media (max-width: 770px) {
        .card_item {
            width: 100% !important;
            max-width: none !important;

            border-radius: unset;
        }

        img {
            height: 250px !important;
        }

        .card-img-top {
            border-radius: unset;
        }

        a {
            flex-basis: unset;
        }

        flex-wrap: wrap;
        justify-content: center;
    }
}
