#app > * {
    margin: auto;
}

#app {
    width: 100%;
    position: relative;
}

.circle-btn {
    z-index: 2;
    position: fixed;
    bottom: 40px;
    right: 40px;

    .button {
        display: block;
        position: relative;
        width: 56px;
        height: 56px;
        margin: 0;
        overflow: hidden;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        border: 0;
    }

    .button:before,
    .button:after {
        content: "";
        position: absolute;
        border-radius: 50%;
        inset: 7px;
    }

    .button:before {
        border: 4px solid #f0eeef;
        transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
        transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
    }

    .button:after {
        border: 4px solid #0052D9;
        transform: scale(1.3);
        transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
        transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        opacity: 0;
    }

    .button:hover:before {
        opacity: 0;
        transform: scale(0.7);
        transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
        transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    .button:hover:after {
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
        transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
    }

    .button-box {
        display: flex;
        position: absolute;
        flex-direction: column;
        top: 0;
        left: 0;
    }

    .button-elem {
        display: block;
        width: 20px;
        height: 20px;
        margin: 18px 20px 20px 15px;
        transform: rotate(270deg);
        fill: #f0eeef;
    }

    .button-elem2 {
        fill: #0052D9;
    }

    .button:hover .button-box {
        transition: 0.4s;
        transform: translateY(-56px);
    }

}
