.bottom-area {
    z-index: 2;
    width: 100%;
    position: relative;
    padding: 20px;

    .bottom-container {
        max-width: 1200px;
        margin: auto;

        h5 {
            font-weight: 300;
        }
    }

    .society-icons {
        margin-left: 20px;

        .card {
            background-color: inherit;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: relative;
            transition: all 0.5s ease-in-out;
            border: none;

            .qr-code {
                display: none;
                position: absolute;
                top: -230px;
                left: 80px;
                transform: translateX(-50%);
                z-index: 100;
            }

            .qr-code img {
                width: 180px;
                height: 230px;
                border-radius: 20px;
            }

            /* 当按钮悬停时显示二维码 */

            button:hover .qr-code {
                display: block;
            }

        }


        .card button {
            border: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            padding: 5px;
            transition: transform .3s ease-in-out;
        }

        .card button:hover {
            transform: translateY(-3px);
        }

        .card button > img {
            font-size: 34px;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            transition: all 0.2s ease-in;
        }
    }

    .follow-us-area {
        width: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f2f3f5;
        padding-bottom: 10px;
    }

    .info-items {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
    }

    .info-area {
        width: 60%;
    }

    @media (max-width: 900px) {
        .info-area {
            width: 100%;
        }

        .company-logo {
            display: none !important;
        }
    }


    .info-item {
        display: flex;
        flex-direction: column;
        /*width: 216px;*/
    }

    .item-options {
        display: flex;
        flex-direction: column;
        gap: 10px;

        a {
            font-size: 16px;
        }
    }

    .company-information {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        color: #5f6464;
        margin-top: 20px;
        margin-bottom: 30px;


        h5 {
            margin-bottom: 20px;
        }

        a {
            text-decoration: none;
            color: #5f6464;
        }

        .company-logo {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            div {
                font-size: 50px;
                font-weight: 700;
                font-family: "SYfont";
                color: black;
            }

            @media (max-width: 800px) {
                div {
                    font-size: 5vw;
                }
            }
        }
    }

    .record-area {
        /*max-width: 800px;*/
        color: #5f6464;
        font-size: 14px;

        .row {
            flex-wrap: wrap !important;
        }

        a {
            text-decoration: none;
            color: #5f6464;
        }
    }

    .mobile-info-item {
        padding: 10px;
        width: 100% !important;
        margin-bottom: 15px;

        .item-title {
            display: flex;
            justify-content: space-between;
        }

        img {
            transition: all .4s;
        }

        .open_collapse {
            padding-top: 15px;
            rotate: 90deg;
        }

        .item-options {
        }

        .item-options a {
            font-size: 14px;
        }
    }

}
