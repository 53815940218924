#header_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    margin: auto;
    color: white;
    user-select: none;
    max-width: 1200px;

    #header_btns {
        display: flex;
        flex-grow: 1;
        justify-content: space-around;
        padding-left: 300px;
    }

    @media (min-width: 1240px) {
        .menu_item {
            width: 120px;
        }
    }

    @media (min-width: 770px) and (max-width: 1200px) {
        #header_btns {
            padding-left: 50px;
        }

        .menu_item {
            text-align: center;
            width: 80px;
        }

        .menu_item a, .menu_item ul li a {
            font-size: 15px !important;
        }
    }
}

.lang_area .s_line {
    display: inline-block;
    font-size: 12px;
    margin: 0 3px;
    position: relative;
    top: -2px;
    opacity: 0.7;
}

.lang_area {
    margin-left: 50px;
    color: white;
}

#header_area {
    z-index: 2;
    width: 100%;
    position: fixed;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.3s;

    #b2s_title {
        cursor: pointer;
    }
}

.carousel-caption {
    h3 {
        font-size: 40px;
    }

    p {
        font-size: 22px;
        opacity: 0.8;
    }

    @media (max-width: 770px) {
        h3 {
            font-size: 25px;
        }

        p {
            font-size: 16px;
            opacity: 0.8;
        }
    }

    .sub_list {
        position: relative;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .sub_list li {
        text-align: start;
        opacity: .7;
        width: 100%;
    }
}

.menu_item_mobile {
    a {
        text-align: start !important;
    }

    .sub_list {
        transition: all .4s;
        list-style: none;
        text-align: start;
        padding: 0;
        margin: 0;
    }

    .sub_list li {
        text-align: start;
        opacity: .7;
        width: 100%;
    }

    .open_collapse {
        rotate: 90deg;
    }

    .one-link {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;

        img {
            transition: all .3s;
        }
    }

}

.menu_item {
    .sub_list {
        display: none;
        position: absolute;
        list-style: none;
        padding: 10px 0 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin: 0;
        width: 100%;
    }

    .sub_list li {
        text-align: center;
        opacity: .7;
        width: 100%;
    }
}

.menu_item, .menu_item_mobile {
    list-style: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    font-weight: 400;


    .sub_list_show {
        display: flex;
        flex-direction: column;
    }


    .sub_list li a {
        word-break: break-word;
    }

    .sub_list li:hover {
        opacity: 1;
        transition: opacity .3s;
    }

    a {
        color: white;
        text-decoration: none;
        max-width: 120px;
        /*width: 10vw;*/
        width: 100%;
        display: inline-block;
        text-align: center;
        padding: 5px;
        position: relative;
    }

    .default_link {
        font-size: 16px;
    }
}

.menu_item_show {
    z-index: 10;
    transition: all 0.5s ease;
    background-color: rgba(255, 255, 255, 1); /* 这里的0.5是透明度值 */
    color: black;

    a {
        color: black;
    }

    .default_link::after {
        content: "";
        width: 60%;
        height: 4px;
        display: inline-block;
        background-color: rgb(33, 81, 209);
        position: absolute;
        border-radius: 15px;
        left: 50%; /* 将下划线移至元素的中心 */
        top: 100%;
        transform: translateX(-50%);
        animation: widthAnimation 0.5s ease forwards;
        z-index: 1;
    }

    .sub_list {
        animation: fadeIn 0.5s;
        background-color: rgba(255, 255, 255, 1); /* 这里的0.5是透明度值 */
    }

    .sub_list li {
        margin-bottom: 10px;
    }
}

@keyframes widthAnimation {
    from {
        width: 0;
    }
    to {
        width: 70%;
    }
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.lang {
    opacity: 0.7;
    cursor: pointer;
}

.current {
    opacity: 1;
}

.card-title:hover {
    color: #0052D9;
}

.side-bar-icon {
    background: url("https://b2s-images-1306824275.cos.ap-nanjing.myqcloud.com/public_icons/sideicon.png") no-repeat 0 0;
    width: 14px;
    height: 15px;
    background-size: 100% 100%;
    display: inline-block;
    margin-left: 10px;
}

.side-bar-icon:hover {
    cursor: pointer;
}

#header_bar_mobile {
    transform: translateX(-100%);
    transition: all 0.5s ease; /* 过渡效果 */
}

/* 动画应用在.box上 */
.open {
    transform: translateX(0) !important;
}

.header_with_back {
    background-color: rgb(33, 40, 48) !important;
}
