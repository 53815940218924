
.article_area {
    width: 100%;
    background-color: white;

    .article_container {
        max-width: 800px;
        margin: auto;
        padding: 15px;
    }

    @media (max-width: 800px) {
        .article_title {
            font-size: 20px !important;
            line-height: 30px!important;
        }

        .article_content {

        }
    }

}
